import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { InfoCircleOutlined } from "@ant-design/icons";

import "styles/dashboard.scss";
import { Modal } from "antd";

interface Props {
  dataGraphePrice: any;
}

const GraphePrice: FC<Props> = ({ dataGraphePrice }) => {
  const chartRef = useRef<any>();
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    drawPriceChart();

    window.addEventListener("resize", drawPriceChart);

    return () => {
      window.removeEventListener("resize", drawPriceChart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGraphePrice]);

  const drawPriceChart = () => {
    if (!_.isEmpty(dataGraphePrice)) {
      const margin = { top: 20, right: 60, bottom: 40, left: 50 };
      const width = 320 - margin.right;
      const height = 320 - margin.top - margin.bottom;

      const data = {
        min: dataGraphePrice.min,
        max: dataGraphePrice.max,
        peek: dataGraphePrice.peek,
        expected: dataGraphePrice.expected,
      };

      // Adjusted Gaussian function
      // const gaussian = (y:number) => {
      //   const mean = data.peek; // Center the peak at `data.peek`
      //   const variance = 10000; // Widen the curve to cover the y-axis range
      //   return (
      //     data.min +
      //     (data.peek - data.min) * Math.exp(-((y - mean) ** 2) / (2 * variance))
      //   );
      // };

      // Asymmetric function for skewed curve
      const asymmetricFunction = (y: number) => {
        const peakPosition = data.peek; // where the peak occurs
        const leftDecay = 1 / (peakPosition - data.min) ** 1.72; // Sharper rise for smaller distance to min
        const rightDecay = 1 / (data.max - peakPosition) ** 1.68; // Slower decay for larger distance to max

        if (y < peakPosition) {
          return (
            data.min +
            (data.peek - data.min) *
              Math.exp(-leftDecay * (y - peakPosition) ** 2)
          );
        } else {
          return (
            data.min +
            (data.peek - data.min) *
              Math.exp(-rightDecay * (y - peakPosition) ** 2)
          );
        }
      };

      // Clear previous chart if it exists
      const isExist = d3.select("#graphe-price-chart");
      if (isExist) {
        d3.select("#graphe-price-chart").remove();
        d3.select(chartRef.current)
          .append("div")
          .attr("id", "graphe-price-chart");
      }

      // Set up scales
      const x = d3.scaleLinear().domain([data.min, data.max]).range([0, width]);
      const y = d3
        .scaleLinear()
        .domain([data.min, data.max])
        .range([height, 0]);

      // Define the line generator with adjusted curve points
      const line = d3
        .line()
        .x((d) => x(d[1]))
        .y((d) => y(d[0]))
        .curve(d3.curveBasis);

      // Generate Gaussian data points
      const gaussianData = d3
        .range(data.min, data.max + 100, 100)
        .map((yValue) => [yValue, asymmetricFunction(yValue)]);

      // Create SVG and append path
      const svg = d3
        .select("#graphe-price-chart")
        .append("svg")
        .attr("width", "100%")
        .attr("height", "100%")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Append Gaussian curve
      svg
        .append("path")
        .datum(gaussianData)
        .attr("class", "gaussian")
        // @ts-ignore
        .attr("d", line);

      // Add y-axis with euro formatting
      const yAxis = d3.axisLeft(y).tickFormat((d) => `${d}€`);
      svg
        .append("g")
        .attr("class", "axis")
        .attr("class", "text-[13px]")
        .call(yAxis);

      // Draw the expected value line
      svg
        .append("line")
        .attr("class", "current-value-line")
        .attr("x1", 0)
        .attr("x2", width)
        .attr("y1", y(data.expected))
        .attr("y2", y(data.expected));

      // Add text annotation for expected value
      svg
        .append("text")
        .attr("class", "annotation")
        .attr("x", 10)
        .attr("y", y(data.expected) - 10)
        .text("Votre facture estimée: ")
        .append("tspan")
        .text(`${data.expected}€/an`)
        .style("font-weight", "bold");
    }
  };

  return (
    <div className="energy-home__graphe-price">
      <div className="flex items-center justify-between mb-4">
        <h5 className="font-bold text-xl">Montant de ma facture</h5>
        <InfoCircleOutlined
          style={{ fontSize: 24 }}
          className="cursor-pointer"
          onClick={() => setIsShowModal(true)}
        />
      </div>
      <div style={{ height: "calc(100% - 44px)" }} ref={chartRef}>
        <div id="graphe-price-chart"></div>
      </div>

      <Modal
        open={isShowModal}
        centered
        onCancel={() => setIsShowModal(false)}
        onOk={() => setIsShowModal(false)}
        cancelButtonProps={{ className: "hidden" }}
      >
        <div className="p-4">
          <p className="text-[16px] mt-1">
            Votre facture énergétique comparée
            <br />
            aux valeurs moyennes du marché
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default GraphePrice;
