import { CognitoUserPool } from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId:
    process.env.REACT_APP_COGNITO_USER_POOL_ID || "eu-west-1_2lbalynag",
  ClientId:
    process.env.REACT_APP_COGNITO_CLIENT_ID || "1p5p03gki5t1cponmpsf74te2b",
};

export const cognitoUserPool = new CognitoUserPool(poolData);
