export const paths = {
  mockData() {
    return `https://pst2ptf774f5e6tabql7g4tle40dyetm.lambda-url.eu-west-1.on.aws`;
  },
  modalData() {
    return `https://tnorlolbmzkwo6gxf5u5awukke0senxc.lambda-url.eu-west-1.on.aws`;
  },
  autoCompleteSearch(search: string) {
    return `https://api-adresse.data.gouv.fr/search/?q=${search}`;
  },
  listingTable() {
    return "https://akwaiji6qo3z5qy2tyeq4amvh40uhqfc.lambda-url.eu-west-1.on.aws";
  },
  getDataUpload(fileName: string) {
    return `https://zlmqrmqcbckmsovecorupci2qu0uvcxr.lambda-url.eu-west-1.on.aws/?file_name=${fileName}`;
  },
  getHomeUploadData() {
    return `https://api-d.ledpe.fr/home/home`;
  },
  getResultat() {
    return `https://api-d.ledpe.fr/home/svg_result`;
  },
  getGEOMap() {
    return "https://gist.githubusercontent.com/wavded/1200773/raw/e122cf709898c09758aecfef349964a8d73a83f3/sample.json";
  },
  submitResultat() {
    return "https://api-d.ledpe.fr/home/submit";
  },
  getDataFiabilite() {
    return `https://api-d.ledpe.fr/home/reliability`;
  },
  getSVGFiabilite() {
    return `https://api-dev.ledpe.fr/home/svg_reliability`;
  },
  getResultAutocomplete() {
    return "https://api-d.ledpe.fr/home/search_dpes_map";
  },
  getReCalCulateResult() {
    return "https://vwpw34wpop5cbyr22frtfoppzy0nzlxl.lambda-url.eu-west-1.on.aws/?id=dd";
  },
  getControlsResult() {
    return "https://api-d.ledpe.fr/backoffice/dpe_list";
  },
  postSelectedRowControl() {
    return "https://api-d.ledpe.fr/backoffice/send";
  },
  sendDataControl(control: string) {
    return `https://api-d.ledpe.fr/backoffice/${control}`;
  },
  searchDpesMap() {
    return "https://api-d.ledpe.fr/home/search_dpes_map";
  },
  chainlit() {
    return "https://chainlit27389273892738927hdjhceziuhdcez7837298.ledpe.fr";
  },
  getSuggestionRenovation() {
    return `https://api-d.ledpe.fr/home-sub/suggested_renovation`;
  },
  getGraphePrice() {
    return `https://api-d.ledpe.fr/home-sub/budget`;
  },
  chatInit() {
    return `https://api-dev.ledpe.fr/home/chat_init`;
  },
};
