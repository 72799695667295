import { useState } from "react";
import { Button, Form, FormProps, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import { cognitoUserPool } from "config"; // Import your Cognito User Pool configuration
import { EmailRegex } from "constants/regex";
import mainInBath from "assets/images/manInBath.svg";
import PAGES from "routes/constants";
import { setConfirmationEmail } from "services/storages/userStorage";

import "styles/signup.scss";

function SignupPage() {
  const form = Form.useFormInstance();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const handleSignup: FormProps["onFinish"] = async (values: any) => {
    console.log("onFinish:", values);
    try {
      setLoading(true);
      const dataEmail = new AmazonCognitoIdentity.CognitoUserAttribute({
        Name: "email",
        Value: values.email,
      });
      cognitoUserPool.signUp(
        values.email,
        values.password,
        [dataEmail],
        [],
        (err, data) => {
          if (err) {
            console.error("Signup failed:", err);
            messageApi.error("Signup failed: " + err.message);
          } else {
            console.log("Signup success:", data);
            setConfirmationEmail(values.email);
            navigate(PAGES.confirmationSignup);
          }
          setLoading(false);
        }
      );
    } catch (e: any) {
      console.log("ERROR", e);
      setLoading(false);
    }
  };

  return (
    <div className="energy-signup">
      <div className="energy-signup__form">
        <h1>Créer un compte LeDPE</h1>

        <Form
          form={form}
          name="signup-form"
          autoComplete="off"
          layout="vertical"
          initialValues={{
            email: "",
            password: "",
          }}
          onFinish={handleSignup}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                pattern: EmailRegex,
                message: "Email is invalid",
              },
            ]}
            normalize={(value) => (value ? value.toLowerCase() : "")} // Convert to lowercase
          >
            <Input placeholder="Adresse email" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
          >
            <Input placeholder="Password" type="password" />
          </Form.Item>

          <Button htmlType="submit" className="btn-connect" loading={loading}>
            Continuer
          </Button>
        </Form>

        <Link
          to={PAGES.login}
          className="flex items-center justify-center gap-x-1 underline font-bold text-[16px] mt-10"
          style={{ color: "black" }}
        >
          J&apos;ai déjà un compte{" "}
          <RightOutlined className="relative top-[1px]" />
        </Link>

        <img
          src={mainInBath}
          alt="main-in-bath"
          className="mx-auto"
          width={280}
        />
      </div>

      {contextHolder}
    </div>
  );
}

export default SignupPage;
