import React from "react";
import { useEffect, useMemo, useState } from "react";
import {
  Link,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Layout, Menu, Drawer, Button } from "antd";
import { LoadingOutlined, MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { useScroll, motion, useTransform } from "framer-motion";
import classNames from "classnames";
import { useMutation } from "@tanstack/react-query";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import InsightPage from "./pages/insight";
import useCommonStore from "./stores/useCommon";

import IconInsight from "assets/images/insights.png";
// import LogoMain from "assets/images/logo-main.png";
// import LogoMobile from "assets/images/logo-mobile.png";
import { ReactComponent as IconUser } from "assets/images/user.svg";

import PAGES, { getMenuNavigation } from "./routes/menu";
import API from "./services/api";
import {
  clearUserInfo,
  clearUserToken,
  getUserInfo,
} from "./services/storages/userStorage";
import { cognitoUserPool } from "./config";

const { Content } = Layout;

function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { id } = useParams();

  // const containerRef = useRef<any>();
  const { scrollYProgress } = useScroll({});
  const navigate = useNavigate();
  const location = useLocation();
  const { isShowInsight, setIsShowInsight } = useCommonStore();

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(location.pathname);
  const headerBg = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["rgba(255, 255, 255, 0)", "rgba(255, 255, 255, 0.7)"]
  );
  const headerFilter = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["blur(0)", "blur(15px)"]
  );
  const menuColor = useTransform(
    scrollYProgress,
    [0, 0.4],
    ["rgba(255, 255, 255, 1)", "rgba(0, 0, 0, 1)"]
  );

  const { mutateAsync: mutateChatInit, isPending: isPendingChatInit } =
    useMutation({
      mutationFn: (params: any) => API.mock.chatInit(params),
      retry: 0,
    });

  const isHomePage = useMemo(() => {
    return !!matchPath(
      {
        path: PAGES.home,
        end: true,
      },
      location.pathname
    );
  }, [location.pathname]);

  useEffect(() => {
    setIsShowInsight(false);

    const navigation = getMenuNavigation(isHomePage);
    for (let item of navigation) {
      if (matchPath(item.key, location.pathname)) {
        setSelectedKeys(item.key);
        return;
      }
    }

    if (
      [PAGES.dashboard, PAGES.acheter, PAGES.vendre, PAGES.renover].some(
        (path) =>
          matchPath(
            {
              path,
              end: true,
            },
            location.pathname
          )
      )
    ) {
      setSelectedKeys(PAGES.home);
    } else {
      setSelectedKeys(location.pathname);
    }

    window.scrollTo({ top: 0 });
  }, [isHomePage, location.pathname, setIsShowInsight]);

  useEffect(() => {
    if (isTabletOrMobile) {
      setIsOpenMenu(false);
    }
  }, [isTabletOrMobile]);

  const handleClickMenu = ({ key }: any) => {
    if (key === "signout") {
      const userInfo = getUserInfo();
      const userInfoAWS = cognitoUserPool.getCurrentUser();

      const user = new AmazonCognitoIdentity.CognitoUser({
        Username: userInfoAWS?.getUsername() || userInfo.username,
        Pool: cognitoUserPool,
      });

      clearUserInfo();
      clearUserToken();
      user.signOut();
      navigate(PAGES.login);
      setIsOpenMenu(false);
      return;
    }
    setIsOpenMenu(false);
    navigate(key);
  };

  const handleToInsight = () => {
    setIsShowInsight(!isShowInsight);
  };

  const handleCloseMenu = () => {
    setIsOpenMenu(false);
  };

  const handleOpenChatAI = async () => {
    try {
      await mutateChatInit({
        ref_ademe: id,
        from: "dashboard",
      });
      handleToInsight();
    } catch (e) {
      console.log("error", e);
    }
  };

  const isAllowedShowInsight = useMemo(() => {
    if (matchPath(PAGES.dashboard, location.pathname)) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const isHomePageAndSubPage = useMemo(() => {
    if (
      [PAGES.home, PAGES.acheter, PAGES.vendre, PAGES.renover].some((path) =>
        matchPath(path, location.pathname)
      )
    ) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const isLoginSignUp = useMemo(() => {
    if (
      [
        PAGES.login,
        PAGES.signup,
        PAGES.confirmationSignup,
        PAGES.forgotPassword,
      ].some((path) => matchPath(path, location.pathname))
    ) {
      return true;
    }
    return false;
  }, [location.pathname]);

  const isLoginPage = matchPath(PAGES.login, location.pathname);

  const antLayoutStyle = useMemo(() => {
    if (isLoginSignUp) {
      return {
        margin: "64px 0 0",
        padding: 0,
      };
    }

    if (!isHomePageAndSubPage) {
      return {
        margin: "64px 0 24px",
        padding: 20,
      };
    }
  }, [isHomePageAndSubPage, isLoginSignUp]);

  return (
    <div
      className={classNames("energy-app", {
        "energy-app--outisde": isLoginSignUp,
      })}
    >
      <Layout>
        <motion.div
          initial={{ backgroundColor: `rgba(255, 255, 255, 0)` }}
          style={{
            backgroundColor: isLoginSignUp ? "#fff" : headerBg,
            backdropFilter: isLoginSignUp ? "none" : headerFilter,
          }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className={classNames("ant-layout-header", {
            // "header--fixed": isHomePageAndSubPage,
            "header--white": !isHomePageAndSubPage,
            "header--absolute-white": isLoginSignUp,
          })}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center gap-x-3 sm:gap-x-2">
              <motion.button
                onClick={() => setIsOpenMenu(!isOpenMenu)}
                className="ant-btn ant-btn-text ant-btn-icon-only btn-burger"
                style={{
                  fontSize: "16px",
                  width: isTabletOrMobile ? 32 : 64,
                  height: 64,
                  color: isHomePageAndSubPage ? menuColor : "#000",
                }}
              >
                <span className="ant-btn-icon">
                  <span className="anticon anticon-menu">
                    <MenuOutlined />
                  </span>
                </span>
              </motion.button>

              {/* {isTabletOrMobile ? (
                <Link to={PAGES.home}>
                  <img src={LogoMobile} alt="logo" className="w-5" />
                </Link>
              ) : (
                <Link to={PAGES.home}>
                  <img src={LogoMain} alt="logo" className="w-24" />
                </Link>
              )} */}

              <Link to={PAGES.home}>
                <motion.h1
                  className="logo"
                  style={{
                    color: isHomePageAndSubPage ? menuColor : "#000",
                  }}
                >
                  LeDPE
                </motion.h1>
              </Link>
            </div>

            {isLoginSignUp && (
              <Link
                to={isLoginPage ? PAGES.signup : PAGES.login}
                className="flex items-center gap-1"
              >
                <IconUser style={{ fill: "black" }} />
                <p className="font-semibold text-[16px] text-black">
                  {isLoginPage ? "Se connecter" : "Pas encore de compte ?"}
                </p>
              </Link>
            )}
          </div>

          {isAllowedShowInsight && (
            <div>
              {isPendingChatInit ? (
                <Button className="w-8 h-8">
                  <LoadingOutlined />
                </Button>
              ) : (
                <img
                  src={IconInsight}
                  alt="insight"
                  width={24}
                  onClick={handleOpenChatAI}
                  className="cursor-pointer"
                />
              )}
            </div>
          )}
        </motion.div>

        <Content
          className={classNames({
            "content--homepage": isHomePageAndSubPage,
          })}
          style={{
            ...antLayoutStyle,
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>

      {/* {isShowInsight && (
          <div className="insight-side">
            <InsightPage />
          </div>
        )} */}

      <Drawer
        title="LeDPE"
        placement="left"
        closable={false}
        onClose={handleCloseMenu}
        open={isOpenMenu}
        className="drawer-menu"
        width={250}
        rootClassName="drawer-custom"
      >
        <Menu
          mode="inline"
          onClick={handleClickMenu}
          selectedKeys={[selectedKeys]}
          items={getMenuNavigation(isHomePage)}
        />
      </Drawer>

      <Drawer
        closable={false}
        placement="right"
        onClose={() => setIsShowInsight(false)}
        open={isShowInsight}
        className="drawer-menu"
        width={300}
        rootClassName="drawer-custom"
      >
        {isShowInsight && <InsightPage />}
      </Drawer>
    </div>
  );
}

export default App;
