import { useRef, useState } from "react";
import { Button, Form, FormProps, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

import { cognitoUserPool } from "config"; // Import your Cognito User Pool configuration
import { EmailRegex } from "constants/regex";
import mainInBath from "assets/images/manInBath.svg";
import PAGES from "routes/constants";

import "styles/forgot-password.scss";

function ForgotPasswordPage() {
  const form = Form.useFormInstance();
  const formPassword = Form.useFormInstance();
  const emailRef = useRef("");

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const handleSendCodeEmail: FormProps["onFinish"] = async (values: any) => {
    console.log("onFinish:", values);
    try {
      setLoading(true);

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: values.email,
        Pool: cognitoUserPool,
      });
      emailRef.current = values.email;

      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          // successfully initiated reset password request
          console.log("CodeDeliveryData from forgotPassword: ", data);
          setLoading(false);
          setStep(2);
        },
        onFailure: function (err) {
          setLoading(false);
          messageApi.error(err.message || JSON.stringify(err));
        },
      });
    } catch (e: any) {
      console.log("ERROR", e);
      setLoading(false);
    }
  };

  const handleUpdateNewPassword: FormProps["onFinish"] = (values: any) => {
    setLoading(true);

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: emailRef.current,
      Pool: cognitoUserPool,
    });

    cognitoUser.confirmPassword(values.code, values.newPassword, {
      onSuccess() {
        messageApi.success("Change password successfully");
        navigate(PAGES.login);
      },
      onFailure(err) {
        setLoading(false);
        messageApi.error("Something wrong: " + err.message);
      },
    });
  };

  const renderFormEmail = () => {
    return (
      <Form
        form={form}
        name="forgot-password-form"
        autoComplete="off"
        layout="vertical"
        initialValues={{
          email: "",
        }}
        onFinish={handleSendCodeEmail}
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
            {
              pattern: EmailRegex,
              message: "Email is invalid",
            },
          ]}
          normalize={(value) => (value ? value.toLowerCase() : "")} // Convert to lowercase
        >
          <Input placeholder="Adresse email" />
        </Form.Item>

        <Button htmlType="submit" className="btn-connect" loading={loading}>
          Continuer
        </Button>
      </Form>
    );
  };

  const renderFormPassword = () => {
    return (
      <Form
        form={formPassword}
        name="forgot-password-form"
        autoComplete="off"
        layout="vertical"
        initialValues={{
          code: "",
          newPassword: "",
          confirmPassword: "",
        }}
        onFinish={handleUpdateNewPassword}
      >
        <Form.Item
          name="code"
          label="Verification code"
          rules={[
            {
              required: true,
              message: "Verification code is required",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="New password"
          rules={[
            {
              required: true,
              message: "New password is required",
            },
          ]}
        >
          <Input type="password" className="w-full" />
        </Form.Item>

        <Form.Item
          label="Confirm new password"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Confirm new password is required",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log("zzzzzzzz", value, getFieldValue("newPassword"));
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Confirm new password does not match")
                );
              },
            }),
          ]}
        >
          <Input type="password" className="w-full" />
        </Form.Item>

        <Button htmlType="submit" className="btn-connect" loading={loading}>
          Confirm
        </Button>
      </Form>
    );
  };

  return (
    <div className="energy-forgot-password">
      <div className="energy-forgot-password__form">
        <h1>Mot de passe oublié</h1>

        {step === 1 ? renderFormEmail() : renderFormPassword()}
        <img
          src={mainInBath}
          alt="main-in-bath"
          className="mx-auto"
          width={280}
        />
      </div>

      {contextHolder}
    </div>
  );
}

export default ForgotPasswordPage;
