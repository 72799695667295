import {
  AppstoreOutlined,
  UnorderedListOutlined,
  SettingOutlined,
  FundProjectionScreenOutlined,
  CalculatorOutlined,
  LoginOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import PAGES, { getDashboardURL } from "./constants";
import { getProject } from "services/storages/projectStorage";
import { getUserToken } from "services/storages/userStorage";

export enum MENU_NAVIGATION_VALUE {
  MAIN = "main",
  LIST = "list",
}

export const getMenuNavigation = (isHomePage: boolean = false) => {
  const isLastProject = getProject();
  const isLogin = getUserToken();

  let MENU_NAVIGATION = [
    {
      key: PAGES.home,
      icon: <AppstoreOutlined />,
      label: "Main",
    },
    {
      key: PAGES.list,
      icon: <UnorderedListOutlined />,
      label: "Projets",
    },
    {
      key: PAGES.reCalculate,
      icon: <CalculatorOutlined />,
      label: "re-calcul",
    },
    {
      key: PAGES.settings,
      icon: <SettingOutlined />,
      label: "Settings",
    },
    ...(isLogin
      ? [
          {
            key: "signout",
            icon: <LogoutOutlined />,
            label: "Sign out",
          },
        ]
      : [
          {
            key: PAGES.login,
            icon: <LoginOutlined />,
            label: "Sign in",
          },
          {
            key: PAGES.signup,
            icon: <UserOutlined />,
            label: "Sign up",
          },
        ]),
  ];

  if (isLastProject) {
    MENU_NAVIGATION.splice(1, 0, {
      key: getDashboardURL(isLastProject),
      icon: <FundProjectionScreenOutlined />,
      label: "Projet en cours",
    });
  }

  if (isHomePage) {
    MENU_NAVIGATION = MENU_NAVIGATION.filter(
      (item) => item.key !== PAGES.reCalculate
    );
  }

  return MENU_NAVIGATION;
};

export default PAGES;
